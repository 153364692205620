/* Main Flex Container */
.app-section-container {
    display: flex;
    justify-content: space-between; /* Space between the two sections */
    align-items: center; /* Vertically align content */
    padding: 20px;
    gap: 20px; /* Add spacing between items */
    flex-wrap: wrap; /* Ensure layout adapts on smaller screens */
}

/* App Store Logo Section */
.app-store-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background-color: #fff;
    border-radius: 15px;
    padding: 20px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    flex: 1;
    max-width: 300px; /* Restrict max width of the logo section */
}

.appStoreLogo {
    height: 120px;
    width: auto;
    margin-top: 10px;
}

/* Text Box */
.text-box {
    flex: 2;
    background-color: #fff;
    border-radius: 15px;
    padding: 20px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

/* Media Query for Responsive Design */
@media (max-width: 768px) {
    .app-section-container {
        flex-direction: column-reverse; /* Stack elements vertically on small screens */
    }

    .text-box {
        text-align: center;
    }

    .app-store-container {
        max-width: 100%; /* Allow full width for smaller screens */
    }
}
