/* Assuming these styles go into App.css or a component-specific CSS file */


.leadHeading{
    background-color: #111;
    color: #f9f9f9;
    font-family: "Outfit_T";
  }



.email-input, .subscribe-button {
    padding: 10px;
    margin-top: 10px;
    min-height: 30px;

    max-width: 300px;
    min-width: 290px;
}

.other-pattern-input{
    padding: 7px;
    margin-top: 5px;
    min-height: 20px;
    border-color: #d3d3d3;
    background-color: rgb(250, 250, 250);
    border-style: solid;
    border-width: 1px;
    border-radius: 5px;
    max-width: 150px;
    min-width: 100px;
}

.subscribe-button {
    background-color: rgb(231, 207, 73);
    color: rgb(16, 16, 16);
    border-radius: 5px;
    border-width: 1px;
    cursor: pointer;
    transition: background-color 0.3s ease;
    font-size: 30px;

    margin: 10px;
}

.subscribe-button:hover {
    background-color: rgb(255, 227, 15);
}

.text-box {
    padding: 20px;
    background-color: #ffffff;
    border-radius: 15px;
    border-width: 2px;
    border-style: none; /* This needs to be specified */
    border-color: #d3d3d3;

    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;


    border-width: 1px;
    border-style: solid;
}

.email-text-box {
    padding: 20px;
    width: 100%;
    background-color: #ffffff;
    border-radius: 5px;
    box-shadow: 2px 2px 15px rgba(0,0,0,0.5);
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
}

.email-text{
    font-size: 1.2rem;
    font-weight: 300;
    max-width: 800px;
    margin-left: auto;
    margin-right: auto;

}

.email-info{
    width: 100%;
    height: auto;
}


.content-wrapper-email {
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    flex-direction: column; /* Ensures the form is centered and stacked */
    max-height: fit-content;
    margin: 0rem;

}

.lead-gen-content {
    padding: 5rem 0;
    text-align: center;
    background-color: rgb(232, 232, 232);
  }


  .leadGenerationSection{
    display: flex;
    position: relative;
    z-index: 10;
    padding: 0rem 0;
border-color: #d3d3d3;

border-right-style: none ;
border-left-style: none ;
border-top-style:solid ;
border-bottom-style:solid ;
    text-align: center;
    /* min-height: 80vh; */
    background-color: white;
    flex-direction: column;
    align-items: center;
}

.pattern-options label {
    margin-right: 20px; /* Space out the options */
    margin-bottom: 40px; /* Space out the options */
    font-family: 'Outfit_T';
}

.pattern-options {
    margin-bottom: 10px; /* Space out the options */
}




.radio-scarfs { accent-color: #ff6961; }
.radio-hats { accent-color: #ffb347; } 
.radio-tote-bags { accent-color: #fdfd96; }
.radio-socks { accent-color: #77dd77; } 
.radio-blankets { accent-color: #84b6f4; } 
.radio-cardigans { accent-color: #cda4de; } 
.radio-shawls { accent-color: #e3a6f2; }

/* Additional style to ensure the color shows nicely */
.pattern-options input[type="radio"] {
    accent-color: inherit; /* This will make the radio button take the background color */
    margin-right: 4px;
}
.pattern-options label {
   
    padding: 1px;
    border-radius: 0px;
    margin-bottom: 10px;
    cursor: pointer;
}

.form-container{
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    max-width: 70%;
    margin:30px
}



.signUpHereText{

font-size: 2rem;
}

  @media (max-width: 768px) {
    .email-text-box {
        width: 100%;
        flex-direction: column;

    }

    .form-container{
        max-width: 90%;
    }


    .signUpHereText{

        margin-top: 20px;
    }
  }
