.section#about {
    padding: 4rem 1rem; /* Padding for the section */
}

.penguinGif {
    width: 100%; /* Full width of the container */
    max-width: 300px; /* Maximum width of the image */
    height: auto; /* Maintain aspect ratio */
}


.teamPhotoContainer{

    width:50%;
}

/* AboutSection.css */
.teamPhoto {
    width: 100%;
    height: auto; /* Maintain aspect ratio */
    transform:  rotateY(0deg); /* Initial state with perspective */
    transition: transform 0.3s ease-in-out, box-shadow 0.3s ease-in-out;
    box-shadow: 0 2px 10px rgba(0,0,0,0.3);
    filter: contrast(1.2) saturate(1.1);
    transform-origin: center center; /* Ensures rotation doesn't skew the image */
}

.teamPhoto:hover {
    transform:  scale(1.03);

    box-shadow: 0 10px 20px rgba(0,0,0,0.7);
}

.text-box-left{
    flex: 1;
    max-width: 500px;
    margin: 1rem;

    padding: 20px;
    background-color: #ffffff;
    border-radius: 15px;
    border-width: 2px;
    border-style: none;
    border-color: #d3d3d3;
    display: flex;
    flex-direction: column;

    justify-content: center;
    border-width: 1px;
    border-style: solid;

    text-align: left;
}

/* Media query for responsiveness */
@media (max-width: 768px) {
    .content-wrapper {
        flex-direction: column; /* Stack items vertically on smaller screens */
        text-align: center; /* Center text for better readability */
    }

    .text-box, .stacked-images {
        padding: 0.5rem;
        max-width: 100%; /* Allows text box to fill container on smaller screens */
    }

    .penguinGif {
        margin-top: 20px; /* Adds some space above the image when stacked */
        max-width: 80%; /* Reduces the size of the image for smaller screens */
    }

    .teamPhotoContainer{
margin-top: 20px;
        width:100%;
    }
}