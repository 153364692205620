/* In App.css */
@font-face {
  font-family: 'Outfit_R';
  src: url('/public/Outfit-Regular.woff2') format('woff2');
  src: url('/public/Outfit-Regular.woff') format('woff');
  src: url('/public/Outfit-Regular.ttf') format('truetype');

}

@font-face {
  font-family: 'Outfit_T';
  src: url('/public/Outfit-Thin.woff2') format('woff2');
  src: url('/public/Outfit-Thin.woff') format('woff');
  src: url('/public/Outfit-Thin.ttf') format('truetype');

}


* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  
}

vb{
  font-weight: 800;
}


body {

  
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-family: 'Outfit_R','Robotica', sans-serif;
  line-height: 1.6;
  color: #333;



  
}

.central-container{

  background-color: white;
  border: #d3d3d3;
  border-width: 1px;
  border-style: solid;
  flex: 1 1;
  display: flex;
  justify-content: center;
  align-content: center;
  align-items: center;
}


.textBoxTitles{

  font-weight: 700;
  font-size: 30px;
  color: #000000;
}


.emailInfo{  
  font-weight: 700;
  font-size: 30px;
  color: #000000;}

.gridTitle{

  transform: translateZ(0);
  font-weight: 900;
  font-size: 130px;
  line-height:134px;
  max-width: 474px;
  color: #000000;
}

.comingSummerText{ 
  transform: translateZ(0);
  font-weight: 900;
  font-size: 50px;
 
  color: rgb(48, 48, 48);
min-width: 90vw;
text-align: center;
 }


.AboutUsHeading{
  /* background-color: #7669dd;
  border-color: #5c4ec0; */

  border-width: 2px;
  border-style: none; /* This needs to be specified */
  /* box-shadow: 5px 5px 0px rgba(0,0,0,0.2); */
}

.OurGoalsHeading{
  /* background-color: #639bdf;
  border-color: #4476b3; */

  border-width: 2px;
  border-style: none; /* This needs to be specified */
  /* box-shadow: 5px 5px 0px rgba(0,0,0,0.2); */
}

.LeadGenerationHeading{
  /* background-color: #5561c1;
  border-color: rgb(74, 61, 171); */

  border-width: 2px;
  border-style: none; /* This needs to be specified */
  /* box-shadow: 5px 5px 0px rgba(0,0,0,0.2); */
}

.ContactHeading{
  /* background-color: #e1a25f;
  border-color: #b27a3e; */

  border-width: 2px;
  border-style: none; /* This needs to be specified */
  /* box-shadow: 5px 5px 0px rgba(0,0,0,0.2); */
}

.AppHeading{
  /* background-color: #e7d457;
  border-color: #c8b640; */

  border-width: 2px;
  border-style: none; /* This needs to be specified */
  /* box-shadow: 5px 5px 0px rgba(0,0,0,0.2); */
}

.ProductHeading{
  /* background-color: #e25757;
  border-color: #bd3c3c; */

  border-width: 2px;
  border-style: none; /* This needs to be specified */
  /* box-shadow: 5px 5px 0px rgba(0,0,0,0.2); */
}

.thanksHeading{
  /* background-color: #e25757;
  border-color: #bd3c3c; */

 
  /* //box-shadow: 5px 5px 0px rgba(0,0,0,0.2); */
}



main {
  margin-top: 5rem;
}


.content {
  padding: 5rem 0;
  /* box-shadow: 5px 10px 18px #888888; */

  background-color: rgb(250, 250, 250);

  height: fit-content;
  padding: 1rem 0; 
  
  border-top-style: solid;
  border-width: 1px;

  border-color: #d3d3d3;


  background-color: rgb(250, 250, 250);
}
/* In App.css or a component-specific CSS file */


.page-text-container{
  display: flex;
  flex-direction: column;
  max-width: 40%;
  align-items: flex-start;
}

/* App.css */
/* Add to your App.css or a specific CSS module for the HeroSection */
.hero {
  position: fixed;
  width: 100%;
  top: 0;
  z-index: -1;
  height: 100vh;  
  display: flex;
  justify-content: center;
  align-items: center;
  transition: background 2s ease; 
}


main {
  position: relative;
  width: 100%;
  margin-top: 100vh;  /* Offset the main content by the full height of the hero */
}


.main-content {

  margin-top: 100vh;  /* Should be equal to the height of the hero section */
}

/* Additional content styles */




.hero h1 {
  font-family: 'Outfit_R';
  color: rgb(10, 10, 10);
  font-size: 6rem;
  font-weight: 300;
  margin-bottom: 1rem;
}

.hero img {
  max-width: 100%;
  width: 100%;
  height: auto;
  display: block;
  margin: 0 auto;
}

h2 {
  font-size: 2.5rem;
  border-radius: 25px;
  padding: 14px;



  margin-bottom: 2rem;

}

.content p {
  font-size: 1.2rem;
  font-weight: 300;
  margin-bottom: 2rem;
  max-width: 800px;
  margin-left: auto;
  margin-right: auto;

}


.content ul {
  list-style: none;
  padding: 0;
}

.content ul li {
  font-size: 1.2rem;
  font-weight: 400;
  margin-bottom: 1rem;
}

#contact {
  background-color: rgb(250, 250, 250);
}


.stacked-images img:nth-child(1) {
  top: 0;
  left: 0;
}

.stacked-images img:nth-child(2) {
  top: 0;
  left: 50px;
}

.stacked-images img:nth-child(3) {
  top: 0;
  left: 100px;
}

/* Add the following styles at the end of the file */
.content-wrapper {
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  align-items: center;
  padding: 1rem;
}


.content-wrapper.reverse {
  flex-direction: row-reverse;
}

.text-box {
  flex: 1;
  max-width: 500px;
  margin: 1rem;

  /* box-shadow: 0 2px 10px rgba(0,0,0,0.3); */
}

.intro-text-box {
  flex: 1;
  max-width: 900px;
  margin: 1rem;

  /* box-shadow: 0 2px 10px rgba(0,0,0,0.3); */
}


.stacked-images img:nth-child(1) {
  top: 0;
  left: 0;
}

.stacked-images img:nth-child(2) {
  top: 0;
  left: 20px;
}

.stacked-images img:nth-child(3) {
  top: 0;
  left: 40px;
  /* width: 400px;
  height: 400px; */
  transform: rotate(4deg);
}

.rotating {
  animation: rotation 30s infinite linear;
}

.opRotating {
  animation: opRotation 10s infinite linear;
}

@keyframes rotation {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
@keyframes opRotation {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(-360deg);
  }
}




.section{
  background-color: rgb(250, 250, 250);
  min-height: 63vh;
  height: fit-content;
  padding: 1rem 0; 
}

/* Add the following styles at the end of the file */
#app {
  /* background-color: #ffffff;
  padding: 2rem 0; */
  flex-direction: row;
}




/* Add media query for responsiveness */
@media (max-width: 768px) {
  .app-images {
    margin-top: 1rem;
  }
}

#app h2 {
  text-align: center;
}

.stacked-images {
  position: relative;
  display: inline-block;
  /* Ensure the container is large enough to fit the largest image comfortably */
  min-width: 233px; 
  min-height: 250px;  
}

.stacked-images img {
  position: absolute;
  width: 200px;  
  height: auto;
  top: 0;  
}

/* Adjusting the main container for the phone image */
.phoneImageContainer {
  display: flex;
  justify-content: center; /* Centers the image horizontally */
  align-items: center; /* Centers the image vertically */
  height: 100%; /* Full height of the container */
}

/* Responsive image styling */
.phoneImage {
  width: 100%; /* Full width of the container */
  height: auto; /* Maintain aspect ratio */
  max-width: 400px; /* Maximum width of the image */
}

.shapefooter{
  width:50px;
  height:50px;
}

.grid-page-container{
  display: flex;

}

/* Media query for mobile devices */
@media (max-width: 768px) {

}


/* Consider responsive adjustments */
@media (max-width: 768px) {
  .stacked-images {
    width: 100%;  
    min-height: 200px;  
  }

  .page-text-container{
    max-width:100%;
    text-align: left;
    
  }

  .gridTitle{

    font-weight: 900;
    font-size: 83px;
    line-height: 87px;
    color: #000000;
  }



  .stacked-images img {
    width: 50%;  
    position: relative;  
    top: auto;
    left: auto;
    transform: none;
  }

  .phoneImageContainer {
    height: auto; /* Height adjusts to content */
}
.phoneImage {
    max-width: 100%; /* Slightly less than full width for padding */
    margin: 0 auto; /* Center the image within the container */
}
    }

    /* Add media query for responsiveness */
@media (max-width: 768px) {
  .content-wrapper {
    flex-direction: column;
  }
  
  .content-wrapper#app {
    flex-direction: column-reverse;
  }

  .text-box {
    max-width: 100%;
  }


.emailInfo{  

  font-size: 20px;

}

  .grid-page-container{
    display: flex;
    flex-direction: column-reverse;
  }
  
  main {

    width: 100%;

  }

}
