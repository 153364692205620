.logos-container {
    display: flex;
    justify-content: space-around; /* Distributes space evenly between logos */
    align-items: center; /* Centers logos vertically */
    padding: 20px 0; /* Adds vertical padding */
    flex-wrap:wrap;
}

.logo-image {
    height: 80px; /* Sets a uniform height for all logos */
    width: auto; /* Maintains aspect ratio */
    opacity: 1; /* Slightly toned down to blend with the design */
    transition: opacity 0.3s ease;

    /* A subtle hover effect to highlight the logo */
   
}

.logo-image:hover {
    opacity: 1;
}

.madeWithSection{
    background-color: rgb(250, 250, 250);

    height: fit-content;
    padding: 1rem 0; 
  
}
