/* Existing styles */

.section#footer {
    min-height: 20vh; /* Increased height for better layout */
    background-color: #f5f5f5; /* Optional: Adds a subtle background color */
    padding-top: 20px; /* Adds space above the logos */
}

.thanksHeading {
    margin-bottom: 0.5rem; /* Reduces space below the heading */
}

/* New styles targeting the link inside thanksHeading */

.thanksHeading a {
    color: black;            /* Make the text color black */
    text-decoration: none;   /* Remove underline */
    font-size: 12px;         /* Make the text small */
}

.thanksHeading a:hover {
    color: black;            /* Ensure the color stays black on hover */
    text-decoration: none;   /* Prevent underline on hover */
}

/* Other existing styles */

.instagramFooter {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
}

.insta-logo-link {
    margin-left: 10px;
}
.app-logo-link {
    margin-top: 7px;
    width: 50px;
}
