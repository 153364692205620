.header {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    background-color: rgba(255, 255, 255, 1);
    padding: 0.7rem;
    z-index: 100;
    display: flex;
    flex-direction: column;
    align-items: stretch;
    border-color: #d3d3d3;
    /* box-shadow: 0px 1px 15px rgba(0,0,0,0.1); */
    border-width: 1px;
    border-bottom-style: solid;
    transition: transform 0.3s ease-in-out; /* Smooth transition for transform */
    font-family: 'Outfit_R';
}


.header-content {
    display: flex;
    width: 100;
    justify-content: space-between;
    align-items: center;
}
.insta-logo-link{
    justify-content: center;
    align-items: center;
    align-self: center;
    display: flex;
    width: 30px;
    height: auto;

}

.penguinInstaContainer{
    display: flex;
    min-width: 30px;
    align-items: center;
    align-content: space-around;
    justify-content: space-between;
}

.headerPenguin {
    width: 50px;
    height: auto;
}

.instaLogo {
    width: 17px;
    height: 17px;
}

.nav-toggle {
    background: none;
    border: none;
    cursor: pointer;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    height: 25px;
    width: 30px;
    padding: 0;
}

.hamburger, .hamburger::before, .hamburger::after {
    display: block;
    background-color: #333;
    height: 3px;
    width: 25px;
    border-radius: 2px;
    transition: all 0.3s ease-in-out;
}

.hamburger::before, .hamburger::after {
    content: "";
    position: absolute;
}

.hamburger::before {
    transform: translateY(-8px);
}

.hamburger::after {
    transform: translateY(8px);
}

.hamburger.open {
    transform: rotate(45deg);
}

.hamburger.open::before {
    transform: rotate(90deg);
}

.hamburger.open::after {
    transform: rotate(90deg);
}

.headerButtons {
    display: none;
    width: 100%;
    justify-content: center;
    background-color: white;
    flex-direction: column;
}

.headerButtons.open {
    display: flex;
}

nav ul {
    list-style: none;
    padding: 0;
    margin: 0;
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
}

nav ul:active {
    background-color: rgb(236, 236, 236);
}

nav ul li {
    padding: 10px;
}

nav ul li a {
    text-decoration: none;
    color: #333;
    font-weight: 100;
    font-size: 20px;

}

nav ul li a:hover {
    color: #999;
}



.header.hidden {
    transform: translateY(-100%);
    transition: transform 0.3s ease-in-out;
}
/* 
@media (min-width: 768px) {
    .nav-toggle {
        display: none;
    }

    .header{
        flex-direction: row;
    }

    .header-content{
        width: 60%;
    }

    .headerButtons {
        display: flex;
        flex-direction: row;
    }

    nav ul {
        flex-direction: row;
    }

    .insta-logo-link{
  
        margin-right: 10px;
    }
} */

