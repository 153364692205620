.grid-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin: 0px;
    padding: 40px;
    width: 60%;
    border-radius: 10px;

}


.grid {
    display: grid;
    grid-template-columns: repeat(4, 1fr); /* Create four columns */
    gap: 10px;
    width: 100%;
    max-width: 800px; /* Adjust based on your design needs */
}

.grid-item {
    width: 90%;
    aspect-ratio: 1 / 1; /* Keeps items square */
    object-fit: contain;
    border-radius: 5px;

    transition: transform 0.3s ease;
}

.grid-item:hover {
    transform: scale(1.15); /* Slight zoom on hover */
}

@media (max-width: 768px) {
.grid-container{
    width: 100%;
    padding: 5px;
}
}