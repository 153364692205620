.hero {
    position: relative;
    height: 100vh;
}

.penguin-container {
    position: absolute;
    bottom: 0;
    right: 10%; /* Closer to the right-hand side */
    text-align: center;
    width: 400px; /* Increase the size */
    height: auto;
    transform: translateY(100%);
    transition: transform 1s ease-in-out, bottom 0.5s ease-in-out;
    z-index: 1000;
}

.penguin-container.visible {
    transform: translateY(-100%); /* Move up to its height */
}

.penguin-image {
    width: 100%;
    height: auto;
}

.speech-text {
    position: absolute;
    font-family: 'Outfit_T';
    top: 23px;
    left: 13px;
    width: 189px;
    height: 80px;
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    color: black;
    font-weight: bold;
    z-index: 1001;
    font-size: 1.5em;
}

@media (max-width: 768px) {
 .penguin-container{
    width:362px
 }

 .speech-text {
    position: absolute;
    font-family: 'Outfit_T';
    top: 17px;
    left: 13px;
    width: 167px;
    height: 80px;
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    color: black;
    font-weight: bold;
    z-index: 1001;
    font-size: 1.5em;
}
}