/* Container styling */
.privacy-policy {
    max-width: 800px;              /* Set a maximum width for readability */
    margin: 40px auto;             /* Center the container and add top/bottom margin */
    padding: 20px;                 /* Add padding inside the container */
    font-family: Arial, sans-serif;/* Use a clean, professional font */
    color: #333;                   /* Dark gray text color for good contrast */
    line-height: 1.6;              /* Increase line spacing for readability */
    background-color: #fff;        /* White background */
    border: 1px solid #ddd;        /* Light gray border */
    border-radius: 5px;            /* Slightly rounded corners */
  }
  
  /* Heading styles */
  .privacy-policy h1 {
    font-size: 2em;
    margin-bottom: 0.5em;
    text-align: center;            /* Center the main heading */
  }
  
  .privacy-policy h2 {
    font-size: 1.5em;
    margin-top: 1.5em;
    margin-bottom: 0.5em;
    color: #444;                   /* Slightly lighter color for subheadings */
  }
  
  /* Paragraph and list styles */
  .privacy-policy p {
    margin-bottom: 1em;
  }
  
  .privacy-policy ul,
  .privacy-policy ol {
    margin-left: 1.5em;            /* Indent lists */
    margin-bottom: 1em;
  }
  
  .privacy-policy li {
    margin-bottom: 0.5em;
  }
  
  /* Link styles */
  .privacy-policy a {
    color: #0066cc;                /* Blue color for links */
    text-decoration: none;         /* Remove underline */
  }
  
  .privacy-policy a:hover {
    text-decoration: underline;    /* Underline on hover */
  }
  
  /* Responsive adjustments */
  @media (max-width: 600px) {
    .privacy-policy {
      padding: 15px;
      margin: 20px;
    }
  
    .privacy-policy h1 {
      font-size: 1.8em;
    }
  
    .privacy-policy h2 {
      font-size: 1.3em;
    }
  }
  