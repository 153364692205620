.HighlightedHeader{
    background-color: rgb(152, 190, 236);
    color: rgb(0, 0, 0);
}

.invisible-text-box{
    flex: 1;
    max-width: 400px;
    margin: 1rem;
}
.invisible-text-box-png{
    flex: 1;
    max-width: 600px;
    margin: 1rem;
}

.goals-text{
    padding: 20px;
    width: 100%;
    background-color: #ffffff;
    border-radius: 5px;
    box-shadow: 0 2px 5px rgba(0,0,0,0.0);
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;

}